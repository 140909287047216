var animarOnViewRect = function () {
	var jaVerificou = false;

	function verificaSeEstaNaViewport() {
		$('[data-animate]').each(function () {

			var animacao = $(this).data('animate');
			var alturaDaViewport = window.innerHeight;
			var positicaoDoElemento = this.getBoundingClientRect().top;

			if (positicaoDoElemento < alturaDaViewport - 20) {
				$(this).addClass(animacao);
			}
		});
	}

	verificaSeEstaNaViewport();

	$(window).scroll(function () {
		verificaSeEstaNaViewport();
	});
}();
