var botao = document.querySelector('.btn-menu');

botao.addEventListener('click', function(event) {
	var target = this.getAttribute('data-target');
	document.querySelector(target).classList.toggle('active');
	this.classList.toggle('active');
	$('.bg-menu').fadeIn(400);
});

$('.bg-menu').click(function () {
	$('.main-navigation').removeClass('active');
	$('.btn-menu').removeClass('active');
	$(this).fadeOut(400);
})